
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { AdvCampaignClient, ExpertClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import { createPreviewFromImage } from '@/utility';

@Options({})
export default class advCampaignDetail extends Vue {

    model: OM.AdvCampaign = new OM.AdvCampaign();
    identifier: string = "";
    file: File = null;
    filePreview: string = "";

    created(){
        this.identifier = this.$route.params.identifier as string;
        this.init();
    }

    init(){
        if(!this.identifier)
            return;

        AdvCampaignClient.getById(this.identifier)
        .then( x => {
            this.model = x;
        })
    }

    save(){
        AdvCampaignClient.save(this.model)
        .then(x => {
            this.$opModal.show(InfoModal, { 
                text:"OK", 
                subtitle: "Salvato", 
                confirm: "CHIUDI", 
                confirmCb: () => {
                    this.$opModal.closeLast()
                } 
            })
            AdvCampaignClient.updatePhoto(x, this.file)
            .then( () => {
                this.identifier = x;
                this.$router.replace("/advCampaigns/" + x);
                this.init();
            })
        })
    }

    async imageChanged(file: File){
        if(!file)
            return;

		this.file = file;
		this.filePreview = await createPreviewFromImage(file);
	}

    get photoImage(){
        if(this.filePreview)
            return this.filePreview;

        if(this.model && this.model.image)
            return this.model.image.publicUrl;

        return "";
    }

}
