import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/advCampaigns',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/advCampaignsList',
                    name: 'advCampaignsList',
                    component: () => import("./views/advCampaignsList.vue")
                },
                {
                    path: '/advCampaigns/:identifier?',
                    name: 'advCampaigns-detail',
                    component: () => import("./views/advCampaignDetail.vue")
                },
            ]
        },
       
    ];
};