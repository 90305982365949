
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Toggler extends mixins(WithModel) {

    @Prop() monocolor: boolean;

    @Prop() readonly: boolean;
    
    toggle(){
        if(this.readonly){
            return;
        }
        this.emitModel(!this.modelValue);
    }

}
