import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { beginModule } from '@/views/begin';
import { dashboardModule } from '@/views/dashboard';
import { dictionaryModule } from '@/views/dictionary';
import { surveyModule } from '@/views/survey';
import { domandeAperteModule } from '@/views/domandeAperte';
import { eventsModule } from '@/views/events';
import { configurationModule } from '@/views/configuration';
import { domandeRompighiaccioConfigModule } from '@/views/domandeRompighiaccioConfig';
import { expertsModule } from '@/views/experts';
import utenti from '@/views/utenti';
import userMatches from '@/views/userMatches';
import chat from '@/views/chat';
import chatNode from '@/views/chatnode';
import optinmeet from '@/views/optinmeet';
import store from '@/store';
import advCampaigns from '@/views/advCampaigns';

const routes: Array<RouteRecordRaw> = [
    ...beginModule.routes(),
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        // beforeEnter: (to, from) => {
        //     if(!store.state.loggedUser.identifier)
        //         return false
        // },
        children: [
            ...dashboardModule.routes(),
            ...dictionaryModule.routes(),
            ...surveyModule.routes(),
            ...eventsModule.routes(),
            ...domandeRompighiaccioConfigModule.routes(),
            ...configurationModule.routes(),
            ...utenti.routes(),
            ...chat.routes(),
            ...optinmeet.routes(),
            ...chatNode.routes(),
            ...expertsModule.routes(),
            ...userMatches.routes(),
            ...domandeAperteModule.routes(),
            ...advCampaigns.routes()
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router